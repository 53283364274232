import { mapActions, mapState } from 'vuex'
import store from '@/store'
import axios from 'axios'
import router from '@/router/'

export default {
  name: 'FiltroReportes',
  components: {
  },
  props: {
    filtroClienteEnable: null,
    filtroEstadoEnable: null,
    filtroCamionEnable: null,
    filtroConductorEnable: null,
    filtroRevisionEnable: null,
    filtroEstadoSiniestroEnable: null,
    filtroEstadoAndinaSupermercadoEnable: null,
    filtroPatenteEnable: null,
    filtroAdministradorFlotaEnable: null,
    downloadButtonaEnable: null
  },
  data: function () {
    return {
      fechaDesde: '',
      fechaHasta: '',
      selectedCliente: null,
      optionsCliente: [
        { value: null, text: 'Cliente' }
        // { value: 'Andina', text: 'Andina' },
        // { value: 'Sopraval', text: 'Sopraval' },
        // { value: 'Soprole', text: 'Soprole' }
      ],
      selectedConductor: null,
      optionsConductor: [
        { value: null, text: 'Conductor' }
      ],
      selectedCamion: null,
      optionsCamion: [
        { value: null, text: 'Camion' }
      ],
      selectedPatente: null,
      optionsPatentes: [
        { value: null, text: 'Patente' }
      ],
      selectedEstado: null,
      optionsEstado: [
        { value: null, text: 'Estado' },
        { value: 'entregada', text: 'Entregada' },
        { value: 'pendiente a salir', text: 'Pendiente a salir' },
        { value: 'en ruta', text: 'En Ruta' },
        { value: 'no realizado', text: 'No realizado' }
      ],
      selecteRevision: null,
      optionsRevision: [
        { value: null, text: 'Estado Revisión' },
        { value: 'R', text: 'Revisada' },
        { value: 'P', text: 'Por revisar' },
        { value: 'E', text: 'Eliminada' }
      ],
      selectedEstadoSiniestro: null,
      optionsEstadoSiniestro: [
        { value: null, text: 'Estado Siniestro' }
      ],
      selectedEstadoAndinaSupermercado: null,
      optionsEstadoAndinaSupermercado: [
        { value: null, text: 'Estado Andina Supermercado' }
      ],
      selectedAdministradorFlota: null,
      optionsAdministradorFlota: [
        { value: null, text: 'Administrador Flota' }
      ]
    }
  },
  watch: {
  },
  mounted () {
    this.getConductores().then((res) => {
      for (const conductor in this.conductores) {
        this.optionsConductor.push({ value: this.conductores[conductor].device_alias, text: this.conductores[conductor].device_alias })
      }
      this.optionsConductor.sort((a, b) => a.text.localeCompare(b.text))
      // this.optionsConductor.sort((a, b) => a.text.localeCompare(b.text))
    })
    this.getCamiones().then((res) => {
      const camionesAux = this.camiones
      for (const camion in camionesAux) {
        const data = {}
        data.text = camionesAux[camion].cam_basis
        data.value = camionesAux[camion].cam_basis
        this.optionsCamion.push(data)
      }
      // this.optionsCamion.sort((a, b) => a.text.localeCompare(b.text))
      for (const camion in camionesAux) {
        const data = {}
        data.text = camionesAux[camion].matricula
        data.value = camionesAux[camion].matricula
        this.optionsPatentes.push(data)
      }
      this.optionsPatentes.sort((a, b) => a.text.localeCompare(b.text))
    })
    this.getClientes().then((res) => {
      // console.log(this.clientes)
      this.clientes.forEach(element => {
        if (this.permisos.some(objeto => objeto.nombre === element.nombre)) {
          this.optionsCliente.push({
            value: element.nombre,
            text: element.nombre
          })
        }
      })
    })
    this.getEstadosSiniestros().then((res) => {
      console.log(this.estados)
      for (const e in this.estados) {
        this.optionsEstadoSiniestro.push({ value: this.estados[e], text: this.estados[e] })
      }
      console.log(this.optionsEstadoSiniestro)
    })
    this.getEstadosAndinaSupermercado().then((res) => {
      console.log(this.estadosAndinaSupermercado)
      for (const e in this.estadosAndinaSupermercado) {
        this.optionsEstadoAndinaSupermercado.push({ value: this.estadosAndinaSupermercado[e], text: this.estadosAndinaSupermercado[e] })
      }
      console.log(this.optionsEstadoAndinaSupermercado)
    })
    this.getAdministradores().then((res) => {
      // console.log(this.administradores)
      this.administradores.forEach(element => this.optionsAdministradorFlota.push({
        value: element.nombre,
        text: element.nombre
      }))
    })
  },
  methods: {
    ...mapActions('Cliente', [
      'getClientes'
    ]),
    ...mapActions('Siniestros', [
      'getSiniestros', 'updateEstadoSiniestro', 'deleteSiniestro', 'getEstadosSiniestros'
    ]),
    ...mapActions('AndinaSupermercado', [
      'getEstadosAndinaSupermercado'
    ]),
    ...mapActions('AdministradorFlota', [
      'getConductores', 'getCamiones'
    ]),
    ...mapActions('Administrador', [
      'getAdministradores'
    ]),
    test () {
      console.log('test')
    },
    Buscar (forDownload) {
      // console.log(this.fechaDesde)
      // console.log(this.fechaHasta)
      // console.log(this.selectedCliente)
      // console.log(this.filtroClienteEnable)
      // console.log(this.filtroEstadoEnable)
      // console.log(this.filtroCamionEnable)
      // console.log(this.filtroConductorEnable)
      // const fechaDesde = this.fechaDesde + 'T00:00:00.000+00:00'
      // const fechaHasta = this.fechaHasta + 'T23:59:00.000+00:00'
      const fechaDesde = this.fechaDesde + ' 00:00:00'
      const fechaHasta = this.fechaHasta + ' 23:59:00'
      // const fechaDesde = this.fechaDesde
      // const fechaHasta = this.fechaHasta
      const periodo = {}
      periodo.fechaDesde = fechaDesde
      periodo.fechaHasta = fechaHasta
      if (this.selectedCliente != null) {
        periodo.cliente = this.selectedCliente
      }
      if (this.selectedCamion != null) {
        periodo.camion = this.selectedCamion
      }
      if (this.selectedConductor != null) {
        periodo.conductor = this.selectedConductor
      }
      if (this.selectedEstado != null) {
        periodo.estado = this.selectedEstado
      }
      if (this.selecteRevision != null) {
        periodo.revision = this.selecteRevision
      }
      if (this.selectedEstadoSiniestro != null) {
        periodo.estadoSiniestro = this.selectedEstadoSiniestro
      }
      if (this.selectedEstadoAndinaSupermercado != null) {
        periodo.estadoAndinaSupermercado = this.selectedEstadoAndinaSupermercado
      }
      if (this.selectedPatente != null) {
        periodo.patente = this.selectedPatente
      }
      if (this.selectedConductor != null) {
        periodo.conductor = this.selectedConductor
      }
      if (this.selectedAdministradorFlota != null) {
        periodo.administrador = this.selectedAdministradorFlota
      }
      periodo.forDownload = forDownload
      this.$emit('dataFiltroReporte', periodo)
      // console.log(periodo)
    },
    async getTransportes (periodo) {
      try {
        const response = await axios({
          method: 'post',
          url: `${store.state.host}/api/vps/vueltas/getTransportes`,
          data: {
            // fechaDesde: '2022-08-19T00:00:00.000+00:00',
            fechaDesde: periodo.fechaDesde,
            fechaHasta: periodo.fechaHasta
          },
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log('success stuff api transporte')
          console.log(response.data)
          return true
        } else {
          // commit('set_token', 'clave o usuario invalido')
          console.log('por else transporte')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch transporte')
        router.push({ path: '/' })
        // dispatch('GetActividadesCoordinador')
        // this.dispatch.GetActividadesCoordinador()
        // commit('set_token', 'clave o usuario invalido')
        return false
      }
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['transportes']),
    ...mapState('AdministradorFlota', ['transportes2']),
    ...mapState('AdministradorFlota', ['camiones']),
    ...mapState('AdministradorFlota', ['conductores']),
    ...mapState('AdministradorFlota', ['transportesNoAsignados']),
    ...mapState('AdministradorFlota', ['listaTransportesStore']),
    ...mapState('KPIStatusTransporte', ['kpi']),
    ...mapState('Cliente', ['clientes']),
    ...mapState('Permisos', ['permisos']),
    ...mapState('Siniestros', ['estados']),
    ...mapState('AndinaSupermercado', ['estadosAndinaSupermercado']),
    ...mapState('Administrador', ['administradores'])
  }
}
