import { mapActions, mapState } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'
import store from '@/store'
import axios from 'axios'
import router from '@/router/'
import FiltroReportes from '@/components/Layout/Menu/Filtros/Filtro_Reportes/FiltroReportes.vue'
import ReportesBopDocumentos from '@/components/Layout/Menu/Reportes/Reportes_bop/Reportes_bop_documentos/ReportesBopDocumentos.vue'
// import BarChart from '@/components/Layout/Menu/Graficos/BarChart/BarChart.vue'
// import store from '@/store'

export default {
  name: 'ReportesBop',
  components: {
    FiltroReportes,
    ReportesBopDocumentos
  },
  props: {
  },
  data: function () {
    return {
      items: [],
      fields: [
        {
          key: 'fecha_transporte',
          label: 'Fecha Transporte',
          sortable: false
        },
        {
          key: 'fecha_agenda',
          label: 'Fecha Agenda',
          sortable: true
        },
        {
          key: 'agenda_vuelta',
          label: 'Agenda Vuelta',
          sortable: false
        },
        {
          key: 'patente',
          label: 'Patente',
          sortable: false
        },
        {
          key: 'id_sitio',
          label: 'Id Sitio',
          sortable: true
        },
        {
          key: 'device_alias',
          label: 'Device Alias',
          sortable: true
        },
        {
          key: 'fecha_hora_inicio',
          label: 'Fecha Inicio',
          sortable: true
        },
        {
          key: 'fecha_hora_fin',
          label: 'Fecha Fin',
          sortable: true
        },
        {
          key: 'v06_centro_costo',
          label: 'Centro Costo',
          sortable: false
        },
        {
          key: 'v06_kilo_inicial',
          label: 'Kilo Inicial',
          sortable: false
        },
        // {
        //   key: 'v06_cliente_destino_bop',
        //   label: 'Cliente Destino',
        //   sortable: false
        // },
        {
          key: 'nombre_cliente',
          label: 'Cliente Destino',
          sortable: false
        },
        {
          key: 'rut_cliente',
          label: 'Rut Cliente',
          sortable: false
        },
        {
          key: 'a06_cond_baj_carga',
          label: 'Cond Baj Carga',
          sortable: false
        },
        {
          key: 'v06_lleva_carro',
          label: 'Lleva Carro',
          sortable: false
        },
        {
          key: 'v06_dev_bop',
          label: 'Dev Bop',
          sortable: false
        },
        {
          key: 'v06_kilo_fin',
          label: 'Kilo Fin',
          sortable: false
        },
        {
          key: 'v06_regreso_cd',
          label: 'Regreso Cd',
          sortable: false
        },
        {
          key: 'nro_docs',
          label: 'Nro docs',
          sortable: false
        },
        {
          key: 'confirmacion_admin',
          label: 'Confirmacion',
          sortable: false
        },
        {
          key: 'Nro Check',
          label: 'v06BoPNroCheckL',
          sortable: false
        },
        {
          key: 'km_incial_GPS',
          label: 'Kilometraje inicial',
          sortable: false
        },
        {
          key: 'km_final_GPS',
          label: 'Kilometraje final',
          sortable: false
        },
        {
          key: 'longitud_inicial',
          label: 'Logitud Incial',
          sortable: false
        },
        {
          key: 'latitud_inicial',
          label: 'Latitud inicial',
          sortable: false
        },
        {
          key: 'longitud_final',
          label: 'Logintud final',
          sortable: false
        },
        {
          key: 'latitud_final',
          label: 'Latitud final',
          sortable: false
        },
        {
          key: 'responsable_cambio',
          label: 'Responsable Cambio',
          sortable: false
        },
        {
          key: 'id_buk',
          label: 'ID Buk',
          sortable: false
        },
        {
          key: 'responsable_cambio',
          label: 'Responsable Cambio',
          sortable: false
        },
        {
          key: 'fecha_cambio',
          label: 'Fecha Cambio',
          sortable: false
        },
        {
          key: 'GeoIni_1',
          label: 'GeoIni_1',
          sortable: false
        },
        {
          key: 'GeoIni_2',
          label: 'GeoIni_2',
          sortable: false
        },
        {
          key: 'GeoFin_1',
          label: 'GeoFin_1',
          sortable: false
        },
        {
          key: 'GeoFin_2',
          label: 'GeoFin_2',
          sortable: false
        },
        {
          key: 'documentos',
          label: 'Documentos',
          sortable: false
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: 'fecha',
      sortDesc: true,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      documentosBopModal: {
        id: 'documentosBop-modal',
        title: '',
        content: ''
      },
      activeColor: 'arial',
      fontSize: 13,
      dataFiltroReporte: {},
      filtroEstadoEnable: true,
      filtroCamionEnable: true,
      filtroConductorEnable: true,
      filtroClienteEnable: true
    }
  },
  watch: {
    dataFiltroReporte: function (val) {
      // console.log('observador dataFiltroReporte')
      // console.log(this.dataFiltroReporte)
      // const periodos = this.PeriodoFechaActual()
      this.PeriodoFechaActual()
      // console.log(periodos)
      if (this.dataFiltroReporte.cliente === null || this.dataFiltroReporte.cliente === undefined) {
        const toast = {}
        toast.title = 'Error'
        toast.body = 'Se debe seleccionar un cliente'
        toast.variant = 'danger'
        this.makeToast(toast)
        return
      }
      this.getReporteBop(this.dataFiltroReporte).then((res) => {
        // console.log('cargo reporte cargas')
        this.items = []
        this.items = this.reporteBop
        console.log(this.items)
        for (const docs in this.items) {
          const geofencesInicial = this.items[docs].geofences_inicial
          const geofencesFinal = this.items[docs].geofences_final
          if (geofencesInicial !== null) {
            for (let j = 0; j < geofencesInicial.length; j++) {
              // const [materiales, cantidad] = geofencesInicial[j].split('-')
              this.items[docs][`GeoIni_${j + 1}`] = geofencesInicial[j]
            }
          }
          if (geofencesFinal !== null) {
            for (let j = 0; j < geofencesFinal.length; j++) {
              // const [materiales, cantidad] = geofencesInicial[j].split('-')
              this.items[docs][`GeoFin_${j + 1}`] = geofencesFinal[j]
            }
          }
          if (this.items[docs].docs !== null) {
            let cont = 0
            this.items[docs].docs.forEach(element => {
              if (element !== null && element.v06_nro_doc !== '' && element.v06_nro_doc !== null) {
                cont += 1
              }
            })
            this.items[docs].nro_docs = cont
          }
        }
        this.totalRows = this.items.length
      })
      this.totalRows = this.items.length
    }
  },
  mounted () {
    const periodos = this.PeriodoFechaActual()
    this.getReporteBop(periodos).then((res) => {
      this.items = this.reporteBop
      console.log(this.items)
      for (const docs in this.items) {
        const geofencesInicial = this.items[docs].geofences_inicial
        const geofencesFinal = this.items[docs].geofences_final
        if (geofencesInicial !== null) {
          for (let j = 0; j < geofencesInicial.length; j++) {
            // const [materiales, cantidad] = geofencesInicial[j].split('-')
            this.items[docs][`GeoIni_${j + 1}`] = geofencesInicial[j]
          }
        }
        if (geofencesFinal !== null) {
          for (let j = 0; j < geofencesFinal.length; j++) {
            // const [materiales, cantidad] = geofencesInicial[j].split('-')
            this.items[docs][`GeoFin_${j + 1}`] = geofencesFinal[j]
          }
        }
        if (this.items[docs].docs !== null) {
          let cont = 0
          this.items[docs].docs.forEach(element => {
            if (element !== null && element.v06_nro_doc !== '' && element.v06_nro_doc !== null) {
              cont += 1
            }
          })
          this.items[docs].nro_docs = cont
        }
      }
      this.totalRows = this.items.length
    })
    this.totalRows = this.items.length
  },
  methods: {
    ...mapActions('AdministradorFlota', [
      'getTransportes', 'getConductores', 'getAyudantes', 'getCamiones'
    ]),
    ...mapActions('Reportes', [
      'getReportePorCargaAcarreoQR', 'getReporteBop'
    ]),
    info (item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal () {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    DocumentosBopModal (item, index, button) {
      this.documentosBopModal.title = `Transporte: ${item.id_transporte}`
      this.documentosBopModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.documentosBopModal.id, button)
    },
    hideDocumentosBopModal () {
      this.$refs[this.documentosBopModal.id].hide()
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    exportarExcel () {
      console.log('exportar excel')
      // XLSX.writeFile(wb, 'cumplimiento.xlsx')
      const itemsAux = []
      let items = {}
      for (const item in this.items) {
        items = {}
        for (const [key, value] of Object.entries(this.items[item])) {
          // console.log('key: ' + key)
          // console.log('value: ' + value )
          if (!key.includes('_cellVariants') && key !== 'docs') {
            // console.log('key: ' + key)
            // console.log('value: ' + value)
            items[key] = value
          }
        }
        itemsAux.push(items)
      }
      const ws = XLSX.utils.json_to_sheet(itemsAux)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'reporte')
      XLSX.writeFile(wb, 'reporte_bop.xlsx')
      // console.log(itemsAux)
      // console.log(semanas)
      // console.log(this.items)
    },
    exportarDetalleExcel () {
      console.log('exportar excel detalle')
      // XLSX.writeFile(wb, 'cumplimiento.xlsx')
      const itemsAux = []
      let items = {}
      // console.log(this.items)
      for (const item in this.items) {
        items = {}
        // console.log(this.items[item].id_transporte)
        // console.log(this.items[item])
        if (this.items[item].docs !== null && this.items[item].docs.length > 0) {
          for (let i = 0; this.items[item].docs.length > i; i++) {
            for (const [key, value] of Object.entries(this.items[item])) {
              // console.log('key: ' + key)
              // console.log('value: ' + value )
              if (!key.includes('_cellVariants') && key !== 'docs') {
                // console.log('key: ' + key)
                // console.log('value: ' + value)
                items[key] = value
              }
            }
            if (this.items[item].docs[i] !== null && this.items[item].docs[i].v06_nro_doc !== '') {
              items.nro_documento = this.items[item].docs[i].nro_documento
              items.v06_coment = this.items[item].docs[i].v06_coment
              items.v06_est_entr = this.items[item].docs[i].v06_est_entr
              items.v06_image = this.items[item].docs[i].v06_image
              items.v06_nro_doc = this.items[item].docs[i].v06_nro_doc
              items.v06_tip_doc = this.items[item].docs[i].v06_tip_doc
              itemsAux.push(items)
              items = {}
            }
          }
        } else {
          for (const [key, value] of Object.entries(this.items[item])) {
            // console.log('key: ' + key)
            // console.log('value: ' + value )
            if (!key.includes('_cellVariants') && key !== 'docs') {
              // console.log('key: ' + key)
              // console.log('value: ' + value)
              items[key] = value
            }
          }
          itemsAux.push(items)
        }
      }
      const ws = XLSX.utils.json_to_sheet(itemsAux)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'reporte')
      XLSX.writeFile(wb, 'reporte_bop_detalle.xlsx')
      // console.log(itemsAux)
      // console.log(this.items)
    },
    async getCargas (periodo) {
      try {
        const response = await axios({
          method: 'post',
          // url: `https://n1ffdxatvc.execute-api.us-west-1.amazonaws.com/prod/cliente/${store.state.cliente}/equipo?marca=${store.state.marca}`,
          // url: `http://${store.state.host}/api/vps/getTransportes`,
          url: `${store.state.host}/api/vps/vueltas/getReportePorCarga`,
          data: {
            // fechaDesde: '2022-08-18 00:00:00',
            // fechaHasta: '2022-08-19 23:00:00'
            fechaDesde: periodo.fechaDesde,
            fechaHasta: periodo.fechaHasta
          },
          headers: {
            Authorization: 'Bearer ' + store.state.token
          }
        })
        if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
          console.log(response.data)
          return response.data
        } else {
          console.log('por else getCargas')
        }
        return false
      } catch (err) {
        console.error(err)
        console.log('por catch getCargas')
        router.push({ path: '/' })
        return false
      }
    },
    PeriodoFechaActual () {
      const date = new Date()
      // const fechaActual = `${
      //   date.getFullYear().toString().padStart(4, '0')}-${
      //     (date.getMonth() + 1).toString().padStart(2, '0')}-${
      //       date.getDate().toString().padStart(2, '0')}T${
      //         date.getHours().toString().padStart(2, '0')}:${
      //           date.getMinutes().toString().padStart(2, '0')}:${
      //             date.getSeconds().toString().padStart(2, '0')}.000+00:00`
      const fechaActualPrimeraHora = `${
        date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth() + 1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')} ${
              '00'}:${
                '00'}:${
                  '00'}`
      const fechaActualUltimaHora = `${
        date.getFullYear().toString().padStart(4, '0')}-${
          (date.getMonth() + 1).toString().padStart(2, '0')}-${
            date.getDate().toString().padStart(2, '0')} ${
              '23'}:${
                '59'}:${
                  '59'}`
      // console.log(fechaActualPrimeraHora)
      // console.log(fechaActualUltimaHora)
      const periodo = {}
      periodo.fechaDesde = fechaActualPrimeraHora
      periodo.fechaHasta = fechaActualUltimaHora
      return periodo
    },
    test () {
      this.getCargas().then((res) => {
        this.items = res
        console.log('cargo')
      })
    },
    makeToast (toast) {
      this.$bvToast.toast(toast.body, {
        title: toast.title,
        variant: toast.variant,
        solid: true
      })
    }
  },
  computed: {
    ...mapState('AdministradorFlota', ['transportes']),
    ...mapState('AdministradorFlota', ['transportes2']),
    ...mapState('AdministradorFlota', ['camiones']),
    ...mapState('AdministradorFlota', ['conductores']),
    ...mapState('AdministradorFlota', ['transportesNoAsignados']),
    ...mapState('AdministradorFlota', ['listaTransportesStore']),
    ...mapState('KPIStatusTransporte', ['kpi']),
    ...mapState('Reportes', ['reporteAcarreoQr']),
    ...mapState('Reportes', ['reporteBop']),
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  }
}
